import FooterSocials from "./socials";
import FooterLinks from "./footer-links";
import FaHouseChimney from "../SVG/Fontawesome/FaHouseChimney";
import FaEnvelope from "../SVG/Fontawesome/FaEnvelope";
import PlayStoreBadge from "../Badge/PlayStore";
import LinkWrapper from "../LinkWrapper";
import FooterSVG from "../SVG/Footer";
import AppStoreBadge from "../Badge/AppStore";
import { memo } from "react";

const Footer = () => {
  return (
    <FooterSVG>
      <footer className="prose max-w-full text-center static">
        {/* <LeadFrom /> */}
        <FooterSocials />
        <hr className="my-0" />
        <div className="body-fluid px-2">
          <FooterLinks />
          <hr className="mb-0 -mx-2" />
          <section className="grid grid-cols-2 grid-flow-row gap-4 mt-5">
            <div className='tracking-wider col-span-2 lg:col-span-1 text-left '>
              {/* <h6 className="uppercase font-semibold mb-4 md:justify-start">
              About Us
            </h6> */}
              <p className="text-sm">
                Doubtnut is No.1 Study App and Learning App with Instant Video Solutions for NCERT Class 6, Class 7, Class 8, Class 9, Class 10, Class 11 and Class 12, IIT JEE prep, NEET preparation and CBSE, UP Board, Bihar Board, Rajasthan Board, MP Board, Telangana Board etc <br className='break-after-auto' />

                NCERT solutions for CBSE and other state boards is a key requirement for students. Doubtnut helps with homework, doubts and solutions to all the questions. It has helped students get under AIR 100 in NEET & IIT JEE. Get PDF and video solutions of IIT-JEE Mains & Advanced previous year papers, NEET previous year papers, NCERT books for classes 6 to 12, CBSE, Pathfinder Publications, RD Sharma, RS Aggarwal, Manohar Ray, Cengage books for boards and competitive exams.
                <br className='break-after-auto' />
                Doubtnut is the perfect NEET and IIT JEE preparation App. Get solutions for NEET and IIT JEE previous years papers, along with chapter wise NEET MCQ solutions. Get all the study material in Hindi medium and English medium for IIT JEE and NEET preparation
                <br className='break-after-auto' />
              </p>
            </div>
            <div className="col-span-2 lg:col-span-1 text-left ">
              <h6 className="uppercase font-semibold md:justify-start">
                Contact Us
              </h6>
              <ul className="pl-0 flex flex-col gap-1">
                <li className="flex md:justify-start items-center space-x-4">
                  <FaHouseChimney />
                  <span>ALLEN CAREER INSTITUTE PRIVATE LIMITED, Plot No. 13 & 14, Dabra Road, Sector 13, Hisar, Haryana 125001</span>
                </li>
                <li className="flex md:justify-start items-center space-x-4">
                  <FaEnvelope />
                  {/* add mailto */}
                  <LinkWrapper href="mailto:info@doubtnut.com" className=" link">info@doubtnut.com</LinkWrapper>
                </li>
              </ul>
            </div>
          </section>
        </div>
        <div className="flex justify-center items-center">
          <PlayStoreBadge linkUrl="https://doubtnut.app.link/GsM96hnmKBb" />
          <AppStoreBadge linkUrl="https://tiny.doubtnut.com/iOS-app-9" />
        </div>
        <hr className="my-2" />
        <ul className="list-none pl-0 pb-28 lg:pb-2 flex justify-center flex-wrap">
          <li>
            <LinkWrapper className=" link space-x-2 shrink-0" href='/terms-and-conditions' target='_blank'>Terms & Conditions</LinkWrapper>
          </li>
          <li>|</li>
          <li>
            <LinkWrapper className=" link space-x-2 shrink-0" href='/payment-terms' target='_blank'>Payment Terms</LinkWrapper>
          </li>
          <li>|</li>
          <li>
            <LinkWrapper className=" link space-x-2 shrink-0" href='/privacy-policy' target='_blank'>Privacy Policy</LinkWrapper>
          </li>
          <li>|</li>
          <li>
            <LinkWrapper className=" link space-x-2 shrink-0" href='/contact-us' target='_blank'>Contact Us</LinkWrapper>
          </li>
          <li>|</li>
          <li>
            <LinkWrapper className=" link space-x-2 shrink-0" href='/about-us' target='_blank'>About Us</LinkWrapper>
          </li>
          <li>|</li>
          <li>
            <span>©2025 Copyright @</span>
            <a className="link  px-0.5" href="/">Doubtnut</a>
          </li>
        </ul>
      </footer>
    </FooterSVG>
  )
};
export default memo(Footer);